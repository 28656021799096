<template>
  <v-select
    v-model="timezone"
    :items="patientTimezoneList"
    dense
    required
    :rules="patientTimezoneRules"
    class="pt-1 mt-0"
    :outlined="isOutlined"
    :item-value="timezone"
  ></v-select>
</template>

<script>
export default {
  name: "timeZoneInput",
  props: {
    isOutlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultValue: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      timezone: "",
      patientTimezoneRules: [
        (v) => !!v || 'Поле "Часовой пояс" обязательно при заполнении',
      ],
      patientTimezoneList: [
        "(UTC-12:00) International Date Line West",
        "(UTC-11:00) Coordinated Universal Time-11",
        "(UTC-10:00) Hawaii",
        "(UTC-09:00) Alaska",
        "(UTC-08:00) Baja California",
        "(UTC-08:00) Pacific Time (US and Canada)",
        "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
        "(UTC-07:00) Arizona",
        "(UTC-07:00) Mountain Time (US and Canada)",
        "(UTC-06:00) Central America",
        "(UTC-06:00) Central Time (US and Canada)",
        "(UTC-06:00) Saskatchewan",
        "(UTC-06:00) Guadalajara, Mexico City, Monterey",
        "(UTC-05:00) Bogota, Lima, Quito",
        "(UTC-05:00) Indiana (East)",
        "(UTC-05:00) Eastern Time (US and Canada",
        "(UTC-04:30) Caracas",
        "(UTC-04:00) Atlantic Time (Canada)",
        "(UTC-04:00) Asuncion",
        "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
        "(UTC-04:00) Cuiaba",
        "(UTC-04:00) Santiago",
        "(UTC-03:30) Newfoundland",
        "(UTC-03:00) Brasilia",
        "(UTC-03:00) Greenland",
        "(UTC-03:00) Cayenne, Fortaleza",
        "(UTC-03:00) Buenos Aires",
        "(UTC-03:00) Montevideo",
        "(UTC-02:00) Coordinated Universal Time-2",
        "(UTC-01:00) Cape Verde",
        "(UTC-01:00) Azores",
        "(UTC+00:00) Casablanca",
        "(UTC+00:00) Monrovia, Reykjavik",
        "(UTC+00:00) Dublin, Edinburgh, Lisbon, London",
        "(UTC+00:00) Coordinated Universal Time",
        "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
        "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
        "(UTC+01:00) West Central Africa",
        "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
        "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
        "(UTC+01:00) Windhoek",
        "(UTC+02:00) Athens, Bucharest, Istanbul",
        "(UTC+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius",
        "(UTC+02:00) Cairo",
        "(UTC+02:00) Damascus",
        "(UTC+02:00) Amman",
        "(UTC+02:00) Harare, Pretoria",
        "(UTC+02:00) Jerusalem",
        "(UTC+02:00) Beirut",
        "(UTC+03:00) Baghdad",
        "(UTC+03:00) Minsk",
        "(UTC+03:00) Kuwait, Riyadh",
        "(UTC+03:00) Nairob",
        "(UTC+03:30) Tehran",
        "(UTC+04:00) Moscow, St. Petersburg, Volgograd",
        "(UTC+04:00) Tbilisi",
        "(UTC+04:00) Yerevan",
        "(UTC+04:00) Abu Dhabi, Muscat",
        "(UTC+04:00) Baku",
        "(UTC+04:00) Port Louis",
        "(UTC+04:30) Kabul",
        "(UTC+05:00) Tashkent",
        "(UTC+05:00) Islamabad, Karachi",
        "(UTC+05:30) Sri Jayewardenepura Kotte",
        "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
        "(UTC+05:45) Kathmandu",
        "(UTC+06:00) Astana",
        "(UTC+06:00) Dhaka",
        "(UTC+06:00) Yekaterinburg",
        "(UTC+06:30) Yangon",
        "(UTC+07:00) Bangkok, Hanoi, Jakarta",
        "(UTC+07:00) Novosibirsk",
        "(UTC+08:00) Krasnoyarsk",
        "(UTC+08:00) Ulaanbaatar",
        "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
        "(UTC+08:00) Perth",
        "(UTC+08:00) Kuala Lumpur, Singapore",
        "(UTC+08:00) Taipei",
        "(UTC+09:00) Irkutsk",
        "(UTC+09:00) Seoul",
        "(UTC+09:00) Osaka, Sapporo, Tokyo",
        "(UTC+09:30) Darwin",
        "(UTC+09:30) Adelaide",
        "(UTC+10:00) Hobart",
        "(UTC+10:00) Yakutsk",
        "(UTC+10:00) Brisbane",
        "(UTC+10:00) Guam, Port Moresby",
        "(UTC+10:00) Canberra, Melbourne, Sydney",
        "(UTC+11:00) Vladivostok",
        "(UTC+11:00) Solomon Islands, New Caledonia",
        "(UTC+12:00) Coordinated Universal Time+12",
        "(UTC+12:00) Fiji, Marshall Islands",
        "(UTC+12:00) Magadan",
        "(UTC+12:00) Auckland, Wellington",
        "(UTC+13:00) Nuku'alofa",
        "(UTC+13:00) Samoa",
      ],
    };
  },
  beforeMount() {
    if (!this.defaultValue) {
      this.timezone = this.$store.getters["user/getUser"].timeZone;
    } else {
      this.timezone = this.defaultValue;
    }
  },
  watch: {
    timezone: function () {
      this.$emit("new-time-zone", this.timezone);
    },
  },
};
</script>

<style scoped></style>
